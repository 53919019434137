// Generated by CoffeeScript 1.8.0
(function() {
  var $;

  $ = this.jQuery;

  $.fn.extend({
    confirmWithReveal: function(options) {
      var defaults, do_confirm, handler, settings;
      if (options == null) {
        options = {};
      }
      defaults = {
        modal_class: 'medium',
        title: 'Are you sure?',
        title_class: '',
        body: 'This action cannot be undone.',
        body_class: '',
        password: false,
        prompt: 'Type <strong>%s</strong> to continue:',
        footer_class: '',
        ok: 'Confirm',
        ok_class: 'button alert',
        cancel: 'Cancel',
        cancel_class: 'button secondary'
      };
      settings = $.extend({}, defaults, options);
      do_confirm = function($el) {
        var confirm_button, confirm_html, confirm_label, el_options, modal, option, password, _ref;
        el_options = $el.data('confirm');
        if ($el.attr('data-confirm') == null) {
          return true;
        }
        if ((typeof el_options === 'string') && (el_options.length > 0)) {
          return (((_ref = $.rails) != null ? _ref.confirm : void 0) || window.confirm).call(window, el_options);
        }
        option = function(name) {
          return el_options[name] || settings[name];
        };
        modal = $("<div data-reveal class='reveal-modal " + (option('modal_class')) + "'>\n  <h2 data-confirm-title class='" + (option('title_class')) + "'></h2>\n  <p data-confirm-body class='" + (option('body_class')) + "'></p>\n  <div data-confirm-footer class='" + (option('footer_class')) + "'>\n    <a data-confirm-cancel class='" + (option('cancel_class')) + "'></a>\n  </div>\n</div>");
        confirm_button = $el.is('a') ? $el.clone() : $('<a/>');
        confirm_button.removeAttr('data-confirm').attr('class', option('ok_class')).html(option('ok')).on('click', function(e) {
          if ($(this).prop('disabled')) {
            return false;
          }
          $el.trigger('confirm.reveal', e);
          if ($el.is('form, :input')) {
            return $el.closest('form').removeAttr('data-confirm').submit();
          }
        });
        modal.find('[data-confirm-title]').html(option('title'));
        modal.find('[data-confirm-body]').html(option('body'));
        modal.find('[data-confirm-cancel]').html(option('cancel')).on('click', function(e) {
          modal.foundation('reveal', 'close');
          return $el.trigger('cancel.reveal', e);
        });
        modal.find('[data-confirm-footer]').append(confirm_button);
        if ((password = option('password'))) {
          confirm_label = (option('prompt')).replace('%s', password);
          confirm_html = "<label>\n  " + confirm_label + "\n  <input data-confirm-password type='text'/>\n</label>";
          modal.find('[data-confirm-body]').after($(confirm_html));
          modal.find('[data-confirm-password]').on('keyup', function(e) {
            var disabled;
            disabled = $(this).val() !== password;
            return confirm_button.toggleClass('disabled', disabled).prop('disabled', disabled);
          }).trigger('keyup');
        }
        modal.appendTo($('body')).foundation().foundation('reveal', 'open').on('closed.fndtn.reveal', function(e) {
          return modal.remove();
        });
        return false;
      };
      if ($.rails) {
        $.rails.allowAction = function(link) {
          return do_confirm($(link));
        };
        return $(this);
      } else {
        handler = function(e) {
          if (!(do_confirm($(this)))) {
            e.preventDefault();
            return e.stopImmediatePropagation();
          }
        };
        return this.each(function() {
          var $el;
          $el = $(this);
          $el.on('click', 'a[data-confirm], :input[data-confirm]', handler);
          $el.on('submit', 'form[data-confirm]', handler);
          return $el;
        });
      }
    }
  });

}).call(this);
